import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { BASE_URL } from "../../utils/config";

export const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  tagTypes: ["Dashboard", "Orders"],
  endpoints: (builder) => ({
    // Get Dashboard Details
    getAdminDashboard: builder.query({
      query: (data) => ({
        url: "admin/dashboard",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
      providesTags: ["Dashboard"],
    }),

    // All Employee List
    getAllEmployee: builder.query({
      query: (data) => ({
        url: "admin/allEmployees",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
    }),

    // Create a Employee
    createEmployee: builder.mutation({
      query: (data) => ({
        url: "auth/create",
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")),
        },
        body: data,
      }),
    }),

    // Get All Feedback API
    getAllFeedbackDetails: builder.query({
      query: (data) => ({
        url: "admin/getRMRating",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
    }),

    // Get User Queries
    getAllUserQueries: builder.query({
      query: (data) => ({
        url: "admin/getUserQuery",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
    }),

    // Get All Generated Orders
    getAllGeneratedOrders: builder.query({
      query: (data) => ({
        url: "admin/getGeneratedOrders",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
    }),

    // Get User All Orders
    getUserAllOrders: builder.query({
      query: (data) => ({
        url: "admin/getAllOrders",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
      providesTags: ["Orders"],
    }),

    // Delete a Order
    deleteOrder: builder.mutation({
      query: (orderID) => ({
        url: `admin/deleteSingleOrder/${orderID}`,
        method: "DELETE",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
      invalidatesTags: ["Orders"],
    }),

    // Get All Clients
    getAllClients: builder.query({
      query: (data) => ({
        url: "admin/getAllClients",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
    }),

    // Get All Payments
    getAllPayments: builder.query({
      query: (data) => ({
        url: "admin/getPayments",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
    }),

    // All Expired Tasks
    getExpiredTasks: builder.query({
      query: (data) => ({
        url: "admin/getExpiredTask",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
    }),
  }),
});

export const {
  // Admin Dashboard API
  useGetAdminDashboardQuery,

  // All Employee List
  useGetAllEmployeeQuery,

  // Create a Employee
  useCreateEmployeeMutation,

  // Get All Feedback API
  useGetAllFeedbackDetailsQuery,

  // User Queries
  useGetAllUserQueriesQuery,

  // User Generated Orders
  useGetAllGeneratedOrdersQuery,

  // Get User All Orders
  useGetUserAllOrdersQuery,

  // Delete Single Order
  useDeleteOrderMutation,

  // Get All Clients
  useGetAllClientsQuery,

  // Get All Payments
  useGetAllPaymentsQuery,

  // Get Expired Tasks
  useGetExpiredTasksQuery,
} = adminApi;
