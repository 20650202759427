import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  forgotOpen: false,
  formDetails: "",
  startupFormDetails: "",
};

export const toolsformdetailsSlice = createSlice({
  name: "toolsformdetails",
  initialState,
  reducers: {
    setForgotDialog: (state, action) => {
      state.forgotOpen = action.payload;
    },
    setFormDetails: (state, action) => {
      state.formDetails = action.payload;
    },
    setStartupFormDetails: (state, action) => {
      state.startupFormDetails = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setForgotDialog, setFormDetails, setStartupFormDetails } =
  toolsformdetailsSlice.actions;

export default toolsformdetailsSlice.reducer;
