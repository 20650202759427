import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { BASE_URL } from "../../utils/config";

export const userLoginApi = createApi({
  reducerPath: "userLoginApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (builder) => ({
    // userLoginData: builder.mutation({
    //   query: (data) => ({
    //     url: "/user/login",
    //     method: "POST",
    //     headers: {
    //       "Content-type": "application/json; charset=UTF-8",
    //       // authorization:
    //       //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFiY0BnbWFpbC5jb20iLCJpYXQiOjE2NTEwNDIzMTEsImV4cCI6MTY1MTMwMTUxMX0.b3COCguragJHtipvsey3ID54x95wjePLmIhnI-8Ia48',
    //     },
    //     body: data,
    //   }),
    // }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: "/user/forgetPassword",
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: data,
      }),
    }),
    // Reset Password after forgot
    resetPassword: builder.mutation({
      query: (data) => ({
        url: `/user/resetPassword/${data.token}`,
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: data,
      }),
    }),

    // Change Password
    changePassword: builder.mutation({
      query: (data) => ({
        url: `/user/changePassword`,
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
    }),
  }),
});

export const {
  useUserLoginDataMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
} = userLoginApi;
