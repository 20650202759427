import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

// Admin APIS
import { adminApi } from "./Admin";

// Sales APIs
import { salesApi } from "./Sales";

// Operation APIs
import { operationApi } from "./Operation";

// Sub-Operation APIs
import { subOperationAPI } from "./SubOperation";

// User APIs
import { userAPI } from "./User";

import { userLoginApi } from "./Auth/userLogin";
// import { userdashboardApi } from './User/dashboard';
// import { userformApi } from './User/userForm';

// State Management
import stateReducer from "./State/stateSlice";
import importantdetailsformReducer from "./State/importantdetailsformSlice";
import toolsformdetailsReducer from "./State/toolsformdetailsSlice";
// import authReducer from './State/authSlice';

export const store = configureStore({
  reducer: {
    [adminApi.reducerPath]: adminApi.reducer,

    [salesApi.reducerPath]: salesApi.reducer,

    [operationApi.reducerPath]: operationApi.reducer,

    [subOperationAPI.reducerPath]: subOperationAPI.reducer,

    [userAPI.reducerPath]: userAPI.reducer,

    [userLoginApi.reducerPath]: userLoginApi.reducer,
    // [userdashboardApi.reducerPath]: userdashboardApi.reducer,
    // [userformApi.reducerPath]: userformApi.reducer,
    statechange: stateReducer,
    importantdetailsform: importantdetailsformReducer,
    toolsformdetails: toolsformdetailsReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      adminApi.middleware,

      salesApi.middleware,

      operationApi.middleware,

      subOperationAPI.middleware,

      userAPI.middleware,

      userLoginApi.middleware
      // userdashboardApi.middleware,
      // userformApi.middleware
    ),
});

// setupListeners(store.dispatch);
