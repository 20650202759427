import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { BASE_URL } from "../../utils/config";

export const userAPI = createApi({
  reducerPath: "userAPI",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  tagTypes: ["Dashboard"],
  endpoints: (builder) => ({
    // User Login API
    userLoginData: builder.mutation({
      query: (data) => ({
        url: "/user/login",
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          // authorization:
          //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFiY0BnbWFpbC5jb20iLCJpYXQiOjE2NTEwNDIzMTEsImV4cCI6MTY1MTMwMTUxMX0.b3COCguragJHtipvsey3ID54x95wjePLmIhnI-8Ia48',
        },
        body: data,
      }),
      invalidatesTags: ["Dashboard"],
    }),

    // User Dashboard API
    getDashboardData: builder.query({
      query: () => ({
        url: "/user/filter/dashboard",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
      providesTags: ["Dashboard"],
    }),

    // User Rating API
    ratingRM: builder.mutation({
      query: (data) => ({
        url: "/user/filter/Rating",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Dashboard"],
    }),

    // User Rating API
    ratingRM: builder.mutation({
      query: (data) => ({
        url: "/user/filter/Rating",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
    }),

    // User Documents (TAB) API
    getUserDoocuments: builder.query({
      query: () => ({
        url: "/user/documents",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
    }),

    // User Company - Important Form Submit API
    submitUserFormData: builder.mutation({
      query: (data) => ({
        url: "/userForm",
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Dashboard"],
    }),

    //User Trademark Form Submit API
    trademarkFormData: builder.mutation({
      query: (data) => ({
        url: "/userForm/trademarkForm",
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Dashboard"],
    }),

    //Show Trademark Preview API
    showTrademarkPreviewAPI: builder.query({
      query: (orderID) => ({
        url: "/userForm/previewTrademark",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: orderID,
      }),
      invalidatesTags: ["Dashboard"],
    }),

    // Confirm Trademark Preview in Trademark API
    confirmTrademarkPreviewFile: builder.mutation({
      query: (orderID) => ({
        url: "userForm/confirmTrademarkPreview",
        method: "POST",
        headers: {
          // 'Content-type': 'application/json; charset=UTF-8',
          // 'Access-Control-Allow-Origin' : '*',
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: { orderID },
      }),
      invalidatesTags: ["Dashboard"],
    }),

    // User OPC - Important Form Submit API
    saveOPCFormSubmitDetails: builder.mutation({
      query: (data) => ({
        url: "/userForm/opc/Form",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Dashboard"],
    }),

    // User LLP - Important Form Submit API
    saveLLPFormSubmitDetails: builder.mutation({
      query: (data) => ({
        url: "/userForm/llp/Form",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Dashboard"],
    }),

    // User PARTNERSHIP - Important Form Submit API
    savePartnershipForm: builder.mutation({
      query: (data) => ({
        url: "/userForm/partnership/Form",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Dashboard"],
    }),

    // User PROPRIETORSHIP - Important Form Submit API
    saveProprietorshipForm: builder.mutation({
      query: (data) => ({
        url: "/userForm/proprietorship/Form",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Dashboard"],
    }),

    // GET CompanyNames of User - API
    getUserCompany: builder.query({
      query: () => ({
        url: "/tools/UserCompany",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
    }),

    // Get PartnerNames According to Company - API
    getPartnerName: builder.mutation({
      query: (data) => {
        console.log("arg", data);
        // const { id, product } = arg;
        return {
          url: `/tools/getDirectorsDetails`,
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: JSON.parse(localStorage.getItem("user")).authToken,
          },
          body: data,
        };
      },
    }),

    //////////////////////////////////////////////////////////////
    ////////////////  LICENSES FORM /////////////////////////////
    // Startup India Form Submit API
    startupIndiaForm: builder.mutation({
      query: (data) => {
        console.log("arg", data);
        return {
          url: `/userForm/licenses/startupindia/Form`,
          method: "PUT",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: JSON.parse(localStorage.getItem("user")).authToken,
          },
          body: data,
        };
      },
      invalidatesTags: ["Dashboard"],
    }),

    //IEC Form Submit API
    IECForm: builder.mutation({
      query: (data) => {
        console.log("arg", data);
        return {
          url: `/userForm/licenses/IEC/Form`,
          method: "PUT",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: JSON.parse(localStorage.getItem("user")).authToken,
          },
          body: data,
        };
      },
      invalidatesTags: ["Dashboard"],
    }),

    //ISO Form Submit API
    ISOForm: builder.mutation({
      query: (data) => {
        console.log("arg", data);
        return {
          url: `/userForm/licenses/ISO/Form`,
          method: "PUT",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: JSON.parse(localStorage.getItem("user")).authToken,
          },
          body: data,
        };
      },
      invalidatesTags: ["Dashboard"],
    }),

    //////////////////////////////////////////////////////////////
    ////////////////  AGREEMENT FORMS ////////////////////////////
    // Founder Agreement Form Submit API
    founderAgreementForm: builder.mutation({
      query: (data) => {
        console.log("arg", data);
        return {
          url: `/userForm/founderAgreement/Form`,
          method: "PUT",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: JSON.parse(localStorage.getItem("user")).authToken,
          },
          body: data,
        };
      },
      invalidatesTags: ["Dashboard"],
    }),

    // Request Modification API
    founderRequest: builder.mutation({
      query: (data) => {
        console.log("arg", data);
        return {
          url: `/user/founderAgreement/requestModification`,
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: JSON.parse(localStorage.getItem("user")).authToken,
          },
          body: data,
        };
      },
      invalidatesTags: ["Dashboard"],
    }),

    // Confirm Founder Agreement Preview API
    founderAgreementPreview: builder.mutation({
      query: (data) => {
        console.log("arg", data);
        return {
          url: `/user/founderAgreement/confirmPreview`,
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: JSON.parse(localStorage.getItem("user")).authToken,
          },
          body: data,
        };
      },
      invalidatesTags: ["Dashboard"],
    }),

    // Admin Other Service API
    adminOtherServices: builder.mutation({
      query: (data) => {
        return {
          url: `/user/extraService`,
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: JSON.parse(localStorage.getItem("user")).authToken,
          },
          body: data,
        };
      },
      invalidatesTags: ["Dashboard"],
    }),

    // Hiring Form API
    hiringFormDetails: builder.mutation({
      query: (data) => {
        return {
          url: "/userForm/hiring/form",
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: JSON.parse(localStorage.getItem("user")).authToken,
          },
          body: data,
        };
      },
      invalidatesTags: ["Dashboard"],
    }),
  }),
});

export const {
  useUserLoginDataMutation,
  useGetDashboardDataQuery,

  // Rating to RM
  useRatingRMMutation,

  useGetUserDoocumentsQuery,
  useSubmitUserFormDataMutation,

  // Trademark APIs
  useTrademarkFormDataMutation,
  useShowTrademarkPreviewAPIQuery,
  useConfirmTrademarkPreviewFileMutation,

  useSaveOPCFormSubmitDetailsMutation,
  useSaveLLPFormSubmitDetailsMutation,
  useSavePartnershipFormMutation,
  useSaveProprietorshipFormMutation,
  useGetUserCompanyQuery,
  useGetPartnerNameMutation,

  //Licences statupForm
  useStartupIndiaFormMutation,
  useIECFormMutation,
  useISOFormMutation,

  //Agreement Forms
  useFounderAgreementFormMutation,
  useFounderRequestMutation,
  useFounderAgreementPreviewMutation,

  // Admin Other Services
  useAdminOtherServicesMutation,

  // Hiring Form Details
  useHiringFormDetailsMutation,
} = userAPI;
