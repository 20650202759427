import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { BASE_URL } from "../../utils/config";

export const subOperationAPI = createApi({
  reducerPath: "subOperationAPI",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  tagTypes: ["Dashboard", "Orders"],
  endpoints: (builder) => ({
    // Get Dashboard Details
    // getAdminDashboard: builder.query({
    //   query: (data) => ({
    //     url: "admin/dashboard",
    //     method: "GET",
    //     headers: {
    //       "Content-type": "application/json; charset=UTF-8",
    //       authorization: JSON.parse(localStorage.getItem("user")).authToken,
    //     },
    //   }),
    //   providesTags: ["Dashboard"],
    // }),

    // All MSME Pending Tasks
    getAllMSMETasks: builder.query({
      query: () => ({
        url: "operation/process/msmePendingTasks",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
    }),

    // All GST Pending Tasks
    getAllGSTTasks: builder.query({
      query: () => ({
        url: "operation/process/allGSTTasks",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
    }),
  }),
});

export const { useGetAllMSMETasksQuery, useGetAllGSTTasksQuery } =
  subOperationAPI;
