import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { BASE_URL } from "../../utils/config";

export const salesApi = createApi({
  reducerPath: "salesApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  tagTypes: [
    "Dashboard",
    "ReceivePayment",
    "PendingTasks",
    "PackageDetails",
    "ProductDetails",
  ],
  endpoints: (builder) => ({
    //   Dashborad API
    getDashboardData: builder.query({
      query: () => ({
        url: "/filter/dashboard",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
      providesTags: ["Dashboard"],
    }),

    // Get User Data from Goole Sheet
    getUserData: builder.mutation({
      query: (UID) => ({
        url: "/client",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: UID,
      }),
    }),

    // Get Operation Employees Detail
    getOperationEmployee: builder.query({
      query: () => ({
        url: "/filter/operationEmployeeDetails",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
    }),

    // POST User Data from Goole Sheet to Database
    postUserData: builder.mutation({
      query: (data) => ({
        url: "/order/newOrder",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: [
        "Dashboard",
        "ReceivePayment",
        "PendingTasks",
        "PackageDetails",
        "ProductDetails",
      ],
    }),

    // Get Payment Details
    getPaymentDetails: builder.query({
      query: () => ({
        url: "/filter/paymentDetails",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
      providesTags: ["ReceivePayment"],
    }),

    // Add Payment Details
    receivePayment: builder.mutation({
      query: (data) => ({
        url: "/payment/recordPayment",
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Dashboard", "ReceivePayment", "PackageDetails"],
    }),

    // Get All Tasks
    getProcessDetails: builder.query({
      query: () => ({
        url: "/filter/process",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
      providesTags: ["PendingTasks"],
    }),

    // Update Pending Tasks
    postProcessDetails: builder.mutation({
      query: (data) => ({
        url: "/process/task",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["PendingTasks"],
    }),

    // Get Compliance Package Details
    getProductData: builder.query({
      query: () => ({
        url: "filter/findByEmployee",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
      providesTags: ["PackageDetails"],
    }),

    // Update Compliance Package
    addExistingCompliance: builder.mutation({
      query: (data) => ({
        url: "/order/updateComplianceOrder",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Dashboard", "PackageDetails"],
    }),

    // Add Other Service of Product
    orderOtherService: builder.mutation({
      query: (data) => ({
        url: "/order/orderOtherProduct",
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["PackageDetails"],
    }),

    // Get Single Product Details
    getSingleProductDetails: builder.mutation({
      query: (orderID) => ({
        url: "filter/singleProductDetails",
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: { orderID },
      }),
    }),

    // Get All Users Details
    getAllUserDetails: builder.query({
      query: (data) => ({
        url: "filter/allUsers",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
    }),

    // Get Single User Product Card Details
    singleUserCardDetails: builder.mutation({
      query: (id) => ({
        url: "/filter/userAllProducts",
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: { id },
      }),
      invalidatesTags: ["PendingTasks"],
    }),

    // Get Single User Documents Details
    singleUserDocuments: builder.mutation({
      query: (id) => ({
        url: "/filter/userAllDocuments",
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: { id },
      }),
      invalidatesTags: ["PendingTasks"],
    }),

    // Add New Compliances (Direct Compliance)
    postDirectComplianceOrder: builder.mutation({
      query: (data) => ({
        url: "/order/directComplianceOrder",
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["PackageDetails"],
    }),

    // Get All Orders History API
    getAllOrderHistoryDetails: builder.query({
      query: (data) => ({
        url: "filter/RMAllProducts",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
    }),
  }),
});

export const {
  useGetDashboardDataQuery,
  useGetUserDataMutation,
  useGetOperationEmployeeQuery,
  usePostUserDataMutation,
  useGetPaymentDetailsQuery,
  useReceivePaymentMutation,
  useGetProcessDetailsQuery,
  usePostProcessDetailsMutation,
  useGetProductDataQuery,
  useAddExistingComplianceMutation,
  useOrderOtherServiceMutation,

  // All User Details API
  useGetAllUserDetailsQuery,
  useSingleUserCardDetailsMutation,
  useGetSingleProductDetailsMutation,

  // Single User Documents Hook
  useSingleUserDocumentsMutation,

  // Direct Compliance Order
  usePostDirectComplianceOrderMutation,

  // All Order History Hooks
  useGetAllOrderHistoryDetailsQuery,
} = salesApi;
