import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { BASE_URL } from "../../utils/config";

export const operationApi = createApi({
  reducerPath: "operationApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  tagTypes: [
    "Dashboard",
    "Form Confirmation",
    "Pending Tasks",
    "Company Details",
    "GST Pending Tasks",
    "GST Application",
  ],
  endpoints: (builder) => ({
    // Employee Login API
    employeeLoginData: builder.mutation({
      query: (data) => ({
        url: "/auth/login",
        method: "POST",
        // headers: {
        //   "Content-type": "application/json; charset=UTF-8",
        //   authorization: JSON.parse(localStorage.getItem("user")).authToken,
        // },
        body: data,
      }),
      invalidatesTags: [
        "Dashboard",
        "Form Confirmation",
        "Pending Tasks",
        "Company Details",
        "GST Pending Tasks",
      ],
    }),

    // Dashboard Operation API
    getOperationDashboardData: builder.query({
      query: () => ({
        url: "/operation/dashboard",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
      providesTags: ["Dashboard"],
    }),

    // Combine Pending Tasks at one place
    getCombinePendingTasks: builder.query({
      query: () => ({
        url: "/operation/process/allPendingTask",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
      providesTags: ["Pending Tasks"],
    }),

    // All User Pending Form Details
    getUserFormDetails: builder.query({
      query: () => ({
        url: "/forms/pendingForms",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
      providesTags: ["Form Confirmation"],
    }),

    // All User Completed Form Details
    getUserCompleteFormDetails: builder.query({
      query: () => ({
        url: "/forms/confirmForms",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
      providesTags: ["Form Confirmation"],
    }),

    // User Company Form Confirmation API
    postUserFormAddionalDetails: builder.mutation({
      query: (data) => ({
        url: "/forms/confirm",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: { data },
      }),
      invalidatesTags: ["Pending Tasks, Form Confirmation"],
    }),

    /*----- 
    ===============  OPC APIs --- Form Confirmation =============
    ------*/
    uploadINCforOPC: builder.mutation({
      query: (data) => ({
        url: "/forms/OPC/uploadINC-3",
        method: "POST",
        headers: {
          // 'Content-type': 'application/json; charset=UTF-8',
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
    }),

    postOPCFormConfirmation: builder.mutation({
      query: (data) => ({
        url: "/forms/OPC/ConfirmForm",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Form Confirmation"],
    }),

    /*----- 
    ===============  LLP APIs --- Form Confirmation =============
    ------*/
    uploadSubscriberSheet: builder.mutation({
      query: (data) => ({
        url: "/forms/LLP/uploadSubscriberSheet",
        method: "POST",
        headers: {
          // 'Content-type': 'application/json; charset=UTF-8',
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
    }),

    postLLPFormConfirmation: builder.mutation({
      query: (data) => ({
        url: "/forms/LLP/ConfirmForm",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Form Confirmation"],
    }),

    // LLP Agreement Upload API
    uploadLLPAgreement: builder.mutation({
      query: (data) => ({
        url: "forms/LLP/uploadLLPAgreement",
        method: "POST",
        headers: {
          // 'Content-type': 'application/json; charset=UTF-8',
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Pending Tasks"],
    }),

    /*----- 
    =============== END LLP APIs =============
    ------*/

    // Add Pending Remark in UserForm API
    userFormPendingRemark: builder.mutation({
      query: (data) => ({
        url: "/forms/addRemark",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Form Confirmation"],
    }),

    // Reject User Form
    postRejectUserForm: builder.mutation({
      query: (data) => ({
        url: "/forms/reject",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
    }),

    // Get GST Compliance API
    getGstCompliance: builder.query({
      query: () => ({
        url: "/operation/compliance/gst",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
    }),

    // Get Statutory Compliance API
    getStatutoryCompliance: builder.query({
      query: () => ({
        url: "/operation/compliance/statutory",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
    }),

    // GET Resubmission API (MCA Application)
    getResubmissionDetails: builder.query({
      query: () => ({
        url: "/operation/process/pendingApplication",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
      providesTags: ["Company Details"],
    }),

    // Add Resubmission API (MCA Application)
    postResubmissionDetails: builder.mutation({
      query: (data) => ({
        url: "/operation/process/addResubmission",
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
    }),

    // Add Rejection API (MCA Application)
    postRejectionDetails: builder.mutation({
      query: (data) => ({
        url: "/operation/process/addRejection",
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
    }),

    // Add COI Details API After Company Registration (MCA Application)
    postCOIDetails: builder.mutation({
      query: (data) => ({
        url: "/operation/process/updateDetails",
        method: "PUT",
        headers: {
          //'Content-type': 'application/json; charset=UTF-8',
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Company Details"],
    }),

    // Send Task From Operation to Sales for Collecting PAN & TAN (Internally)
    generateCOITask: builder.mutation({
      query: (data) => ({
        url: "/operation/process/generateCOITask",
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
    }),

    // GET All Tasks API --> (deprecated)
    // getOperationProcessDetails: builder.query({
    //   query: () => ({
    //     url: "/operation/process/pendingTask",
    //     method: "GET",
    //     headers: {
    //       "Content-type": "application/json; charset=UTF-8",
    //       authorization: JSON.parse(localStorage.getItem("user")).authToken,
    //     },
    //   }),
    //   providesTags: ["Pending Tasks"],
    // }),

    // Update Tasks API
    postProcessDetails: builder.mutation({
      query: (data) => ({
        url: "operation/process/task",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Pending Tasks"],
    }),

    // MSME Certificate Upload API
    uploadMSMECertificate: builder.mutation({
      query: (data) => ({
        url: "operation/process/msme/upload",
        method: "POST",
        headers: {
          // 'Content-type': 'application/json; charset=UTF-8',
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Pending Tasks"],
    }),

    /*----- 
    =======================================================
    ===============  Trademark APIs --- START =============
    =======================================================
    ------*/

    // Trademark - Pending Forms
    getTrademarkPendingForms: builder.query({
      query: () => ({
        url: "/forms/trademarkPendingForms",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
      providesTags: ["Form Confirmation"],
    }),

    // Trademark - Form Confirmation API
    confirmTrademarkForm: builder.mutation({
      query: (data) => ({
        url: "/forms/confirmTrademarkForms",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Form Confirmation"],
    }),

    // GET Trademark Pending Tasks API --> (deprecated)
    // getTrademarkPendingTasks: builder.query({
    //   query: () => ({
    //     url: "/operation/process/pendingTrademarkTask",
    //     method: "GET",
    //     headers: {
    //       "Content-type": "application/json; charset=UTF-8",
    //       authorization: JSON.parse(localStorage.getItem("user")).authToken,
    //     },
    //   }),
    //   providesTags: ["Pending Tasks"],
    // }),

    // Update Trademark Pedning Tasks API
    updateTrademarkPendingTask: builder.mutation({
      query: (data) => ({
        url: "operation/process/doneTrademarkTask",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Pending Tasks"],
    }),

    // Upload Preview File in Trademark API
    uploadTrademarkApply: builder.mutation({
      query: (data) => ({
        url: "forms/applyTrademark",
        method: "POST",
        headers: {
          // 'Content-type': 'application/json; charset=UTF-8',
          // 'Access-Control-Allow-Origin' : '*',
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Pending Tasks"],
    }),

    // Upload Preview File in Trademark API
    uploadTrademarkPreviewFile: builder.mutation({
      query: (data) => ({
        url: "forms/uploadPreview",
        method: "POST",
        headers: {
          // 'Content-type': 'application/json; charset=UTF-8',
          // 'Access-Control-Allow-Origin' : '*',
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Pending Tasks"],
    }),

    // Confirm Trademark Preview in Trademark API
    // confirmTrademarkPreviewFile: builder.mutation({
    //   query: (orderID) => ({
    //     url: "userForm/confirmTrademarkPreview",
    //     method: "POST",
    //     headers: {
    //       // 'Content-type': 'application/json; charset=UTF-8',
    //       // 'Access-Control-Allow-Origin' : '*',
    //       authorization: JSON.parse(localStorage.getItem("user")).authToken,
    //     },
    //     body: { orderID },
    //   }),
    //   invalidatesTags: ["Pending Tasks", "Dashboard"],
    // }),

    // Pending Trademark Application API
    getTrademarkPendingApplication: builder.query({
      query: () => ({
        url: "/forms/pendingTrademarkApplication",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
      providesTags: ["Pending Tasks"],
    }),

    // Add Trademark Opposed / Hearing API
    addTrademarkOpposed: builder.mutation({
      query: (data) => ({
        url: "/forms/opposedTrademark",
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      providesTags: ["Pending Tasks"],
    }),

    // Upload Registered Trademark API
    uploadRegisterdTrademark: builder.mutation({
      query: (data) => ({
        url: "/forms/registeredTrademark",
        method: "POST",
        headers: {
          // 'Content-type': 'application/json; charset=UTF-8',
          // 'Access-Control-Allow-Origin' : '*',
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Pending Tasks"],
    }),

    // GET All GST(Goods & Service Tax) Taks
    getGSTOperationTasks: builder.query({
      query: () => ({
        url: "/operation/process/getGstTask",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
      providesTags: ["Pending Tasks", "GST Pending Tasks"],
    }),

    // Generate TRN Number API
    generateTRN: builder.mutation({
      query: (data) => ({
        url: "operation/process/generateTRN",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Pending Tasks", "GST Pending Tasks"],
    }),

    // Send Aadhar Authentication for GST API
    sendAadharAuthforGST: builder.mutation({
      query: (data) => ({
        url: "operation/process/sendTaskForAadharAuthGst",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Pending Tasks", "GST Pending Tasks"],
    }),

    // GST Registration with ARN Number API
    gstRegistrationARN: builder.mutation({
      query: (data) => ({
        url: "operation/process/gstRegistration",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Pending Tasks", "GST Pending Tasks"],
    }),

    // Gst Application Status
    gstApplicationStatus: builder.query({
      query: () => ({
        url: "/operation/process/gstStatus",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
      providesTags: ["Pending Tasks", "GST Pending Tasks", "GST Application"],
    }),

    // Add GST Resubmission API
    addGSTResubmission: builder.mutation({
      query: (data) => ({
        url: "operation/process/addGstResubmission",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: [
        "Pending Tasks",
        "GST Pending Tasks",
        "GST Application",
      ],
    }),

    // Done GST Resubmission  API
    doneGSTResubmission: builder.mutation({
      query: (data) => ({
        url: "operation/process/doneGstResubmission",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: [
        "Pending Tasks",
        "GST Pending Tasks",
        "GST Application",
      ],
    }),

    // Add GST Resubmission API
    addGstRejection: builder.mutation({
      query: (data) => ({
        url: "operation/process/addGstRejection",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: [
        "Pending Tasks",
        "GST Pending Tasks",
        "GST Application",
      ],
    }),

    // Add GST Number API
    addGstNumber: builder.mutation({
      query: (data) => ({
        url: "operation/process/addGstNumber",
        method: "PUT",
        headers: {
          // "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: [
        "Pending Tasks",
        "GST Pending Tasks",
        "GST Application",
      ],
    }),

    //******************************************//
    //********** PARTNERSHIP APIS - START*******//
    //******************************************//

    // Partnership Form Confirmation APIs
    postPartnershipFormConfirmation: builder.mutation({
      query: (data) => ({
        url: "/forms/Partnership/ConfirmForm",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Form Confirmation"],
    }),

    // Upload Partnership Docs API After Company Registration (MCA Application)
    uploadPartnershipDOCS: builder.mutation({
      query: (data) => ({
        url: "/operation/process/uploadPartnershipDocuments",
        method: "PUT",
        headers: {
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Company Details"],
    }),

    //********** PARTNERSHIP APIS - END *******//

    //******************************************//
    //********** PROPRIETORSHIP APIS - START*******//
    //******************************************//

    // PROPRIETORSHIP Form Confirmation APIs
    proprietorshipFormConfirmation: builder.mutation({
      query: (data) => ({
        url: "/forms/Proprietorship/ConfirmForm",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Form Confirmation"],
    }),

    //********** PROPRIETORSHIP APIS - END *******//

    // User Timeline for Operation
    getUserTimeline: builder.query({
      query: (arg) => {
        const { orderID, product } = arg;
        return {
          url: `/log/operationLog?orderID=${orderID}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: JSON.parse(localStorage.getItem("user")).authToken,
          },
        };
      },
      providesTags: ["Form Confirmation"],
    }),

    // User Documents for Operation
    singleOrderDocuments: builder.query({
      query: (arg) => {
        const { orderID, product } = arg;
        return {
          url: `/operation/process/singleOrderDocument?orderID=${orderID}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: JSON.parse(localStorage.getItem("user")).authToken,
          },
        };
      },
      providesTags: ["Form Confirmation"],
    }),

    // Tools APIs
    getUserDetailsByProduct: builder.query({
      query: (arg) => {
        const { product } = arg;
        return {
          url: `/operation/process/temp?product=${product}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: JSON.parse(localStorage.getItem("user")).authToken,
          },
        };
      },
      providesTags: ["Form Confirmation"],
    }),

    // User View Details API
    getUserViewDetails: builder.query({
      query: (arg) => {
        const { orderID, product } = arg;
        return {
          url: `/operation/process/viewConfirmedForm?orderID=${orderID}&product=${product}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: JSON.parse(localStorage.getItem("user")).authToken,
          },
        };
      },
      providesTags: ["Form Confirmation"],
    }),

    ////////////////////////////////////////////////
    ////////// Licenses APIs ==> START ////////////
    ///////////////////////////////////////////////

    // Pending Forms
    getLisensePendingForms: builder.query({
      query: () => ({
        url: "/forms/Licenses/PendingForm",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
      providesTags: ["Form Confirmation"],
    }),

    // Upload Startup INDIA Authorization Letter
    uploadSTARTUPAuthorization: builder.mutation({
      query: (data) => ({
        url: "/forms/Licenses/Upload/StartupIndiaAuthorization",
        method: "POST",
        headers: {
          //'Content-type': 'application/json; charset=UTF-8',
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Form Confirmation"],
    }),

    // Startup INDIA Form Confirmation
    confirmSTARTUPINDIAForm: builder.mutation({
      query: (data) => ({
        url: "/forms/Licenses/ConfirmForm",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Form Confirmation"],
    }),

    // IEC Form Confirmation
    confirmIECForm: builder.mutation({
      query: (data) => ({
        url: "/forms/Licenses/IEC/ConfirmForm",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Form Confirmation"],
    }),

    // ISO Form Confirmation
    confirmISOForm: builder.mutation({
      query: (data) => ({
        url: "/forms/Licenses/ISO/ConfirmForm",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Form Confirmation"],
    }),

    //Pending Tasks for LICENSES
    pendingTasksForLicenses: builder.query({
      query: () => ({
        url: "/operation/process/License/pendingTask",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
      providesTags: ["Pending Tasks"],
    }),

    //Upload License Application in Pending Tasks
    uploadLicenseApplication: builder.mutation({
      query: (data) => ({
        url: "operation/process/License/uploadStartupApplication",
        method: "POST",
        headers: {
          // 'Content-type': 'application/json; charset=UTF-8',
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Pending Tasks"],
    }),

    // Upload IEC License Application in Pending Tasks
    uploadIECLicenseApplication: builder.mutation({
      query: (data) => ({
        url: "forms/Licenses/Upload/IECLicense",
        method: "POST",
        headers: {
          // 'Content-type': 'application/json; charset=UTF-8',
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Pending Tasks"],
    }),

    // Upload ISO License Application in Pending Tasks
    uploadISOLicenseApplication: builder.mutation({
      query: (data) => ({
        url: "forms/Licenses/Upload/ISOLicense",
        method: "POST",
        headers: {
          // 'Content-type': 'application/json; charset=UTF-8',
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Pending Tasks"],
    }),

    //License Pending Application
    licensePendingApplication: builder.query({
      query: () => ({
        url: "/operation/process/License/pendingApplication",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
      }),
      providesTags: ["Pending Tasks"],
    }),

    //Upload License Application in Upload Documents (Final)
    uploadLicenseDocuments: builder.mutation({
      query: (data) => ({
        url: "operation/process/License/uploadLicense",
        method: "POST",
        headers: {
          // "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Pending Tasks"],
    }),
    ////////// Licenses APIs ==> END //////////////

    // ===============================================

    ////////////////////////////////////////////////
    ////////// Agreement APIs ==> START ///////////
    //////////////////////////////////////////////

    // Upload Founder Agreement
    uploadFounderAgreement: builder.mutation({
      query: (data) => ({
        url: "/forms/FoundersAgreement/uploadfounderAgreement",
        method: "POST",
        headers: {
          //'Content-type': 'application/json; charset=UTF-8',
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Pending Tasks, Form Confirmation"],
    }),

    // confirm Founders Agreement Form
    confirmFounderAgreementForm: builder.mutation({
      query: (data) => ({
        url: "/forms/FoundersAgreement/ConfirmForm",
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Form Confirmation"],
    }),

    ////////// Agreement APIs ==> END ///////////

    ///////// OTher Services APIs ==> START /////////

    // For "Done" Button
    doneBtnOtherService: builder.mutation({
      query: (data) => ({
        url: "/operation/process/otherService/Done",
        method: "POST",
        headers: {
          // 'Content-type': 'application/json; charset=UTF-8',
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Pending Tasks"],
    }),

    // For "Send Task to Sale" Button
    sendTasktoSaleBtnOtherService: builder.mutation({
      query: (data) => ({
        url: "/operation/process/otherService",
        method: "POST",
        headers: {
          // 'Content-type': 'application/json; charset=UTF-8',
          authorization: JSON.parse(localStorage.getItem("user")).authToken,
        },
        body: data,
      }),
      invalidatesTags: ["Pending Tasks"],
    }),

    ///////// OTher Services APIs ==> END   /////////
  }),
});

export const {
  // Employee Login
  useEmployeeLoginDataMutation,

  useGetOperationDashboardDataQuery,
  useGetCombinePendingTasksQuery,
  useGetUserFormDetailsQuery,
  useGetUserCompleteFormDetailsQuery,
  usePostUserFormAddionalDetailsMutation,

  //OPC form Confirmation
  useUploadINCforOPCMutation,
  usePostOPCFormConfirmationMutation,

  //LLP form Confirmation
  useUploadSubscriberSheetMutation,
  usePostLLPFormConfirmationMutation,
  useUploadLLPAgreementMutation,

  // Partnership Form Confirmation
  usePostPartnershipFormConfirmationMutation,
  useUploadPartnershipDOCSMutation,

  // Proprietorship Form Confirmation
  useProprietorshipFormConfirmationMutation,

  // Compliance Api
  useGetGstComplianceQuery,
  useGetStatutoryComplianceQuery,

  useGetResubmissionDetailsQuery,
  usePostResubmissionDetailsMutation,
  useUserFormPendingRemarkMutation,
  usePostRejectionDetailsMutation,

  usePostRejectUserFormMutation,

  usePostCOIDetailsMutation,
  useGenerateCOITaskMutation,

  // useGetOperationProcessDetailsQuery,
  usePostProcessDetailsMutation,
  useUploadMSMECertificateMutation,

  // Trademark API Hooks
  useGetTrademarkPendingFormsQuery,
  useConfirmTrademarkFormMutation,
  useGetTrademarkPendingTasksQuery,
  useUpdateTrademarkPendingTaskMutation,

  // Upload Application File
  useUploadTrademarkApplyMutation,

  useUploadTrademarkPreviewFileMutation,
  // useConfirmTrademarkPreviewFileMutation,
  useGetTrademarkPendingApplicationQuery,
  useAddTrademarkOpposedMutation,
  useUploadRegisterdTrademarkMutation,

  useGetGSTOperationTasksQuery,
  useGenerateTRNMutation,
  useSendAadharAuthforGSTMutation,
  useGstRegistrationARNMutation,
  useGstApplicationStatusQuery,
  useAddGSTResubmissionMutation,
  useDoneGSTResubmissionMutation,
  useAddGstRejectionMutation,
  useAddGstNumberMutation,

  // User Timeline
  useGetUserTimelineQuery,

  //Single Order Documents
  useSingleOrderDocumentsQuery,

  useGetUserDetailsByProductQuery,
  useGetUserViewDetailsQuery,

  // Licenses API Hooks
  useGetLisensePendingFormsQuery,
  useUploadSTARTUPAuthorizationMutation,
  useConfirmSTARTUPINDIAFormMutation,

  // Confirm API of IEC Form
  useConfirmIECFormMutation,
  useUploadIECLicenseApplicationMutation,

  //Confirm API of ISO Form
  useConfirmISOFormMutation,
  useUploadISOLicenseApplicationMutation,

  usePendingTasksForLicensesQuery,
  useUploadLicenseApplicationMutation,
  useLicensePendingApplicationQuery,
  useUploadLicenseDocumentsMutation,

  // Agreement API Hooks
  useUploadFounderAgreementMutation,
  useConfirmFounderAgreementFormMutation,

  // Other Services API Hooks
  useDoneBtnOtherServiceMutation,
  useSendTasktoSaleBtnOtherServiceMutation,
} = operationApi;
