import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentStep: 1,

  dialogopen: false,
  rejectionopen: false,
  gstNumberopen: false,
  OPCConfirmOpen: false,
  LLPConfirmOpen: false,
  PartnershipConfirmOpen: false,
  ProprietorshipOpen: false,

  openPANTask: false,

  trademarkTaskboxOpen: false,
  trademarkStatusTaskOpen: false,

  trademarkApplyTaskOpen: false,

  licenseFormconfirm: false,

  multiStepFormDetails: "",
};

export const stateSlice = createSlice({
  name: "statechange",
  initialState,
  reducers: {
    setPreviousStep: (state, action) => {
      state.currentStep = action.payload - 1;
    },

    setNextStep: (state) => {
      state.currentStep += 1;
    },

    setClearStep: (state) => {
      state.currentStep = 1;
    },

    setHandleDialog: (state, action) => {
      console.log(action);
      state.dialogopen = action.payload;
    },

    setHandleRejectionDialog: (state, action) => {
      console.log(action);
      state.rejectionopen = action.payload;
    },

    setHandleGSTNumberDialog: (state, action) => {
      console.log(action);
      state.gstNumberopen = action.payload;
    },

    setOPCConfirmOpen: (state, action) => {
      console.log(action);
      state.OPCConfirmOpen = action.payload;
    },
    setLLPConfirmOpen: (state, action) => {
      console.log(action);
      state.LLPConfirmOpen = action.payload;
    },
    setPartnershipConfirmOpen: (state, action) => {
      console.log(action);
      state.PartnershipConfirmOpen = action.payload;
    },
    setProprietorshipOpen: (state, action) => {
      console.log(action);
      state.ProprietorshipOpen = action.payload;
    },

    setPANTaskOpen: (state, action) => {
      console.log(action);
      state.openPANTask = action.payload;
    },

    setTrademarkTaskOpen: (state, action) => {
      console.log(action);
      state.trademarkTaskboxOpen = action.payload;
    },

    setHandleTrademarkStatusTaskOpen: (state, action) => {
      state.trademarkStatusTaskOpen = action.payload;
    },

    setHandleTrademarkApplyTaskOpen: (state, action) => {
      state.trademarkApplyTaskOpen = action.payload;
    },

    // License Form confirm Store
    setHandleLicenseFormconfirm: (state, action) => {
      state.licenseFormconfirm = action.payload;
    },

    // set MultiStep form Details
    setMultiFormDetails: (state, action) => {
      state.multiStepFormDetails = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPreviousStep,
  setNextStep,
  setClearStep,
  setHandleDialog,
  setHandleRejectionDialog,
  setHandleGSTNumberDialog,
  setOPCConfirmOpen,
  setLLPConfirmOpen,
  setPartnershipConfirmOpen,
  setProprietorshipOpen,
  setPANTaskOpen,
  setTrademarkTaskOpen,
  setHandleTrademarkStatusTaskOpen,
  setHandleTrademarkApplyTaskOpen,
  setHandleLicenseFormconfirm,
  setMultiFormDetails,
} = stateSlice.actions;

export default stateSlice.reducer;
