import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentStep: 1,
  companyDetails: "",
  llpDetails: "",
  // formDetails: '',
  dialogOpen: false,
  orderID: "",

  trademarkRatingOpen: false,
  licenseRatingOpen: false,
  agreementRatingOpen: false,
};

export const importantdetailsformSlice = createSlice({
  name: "importantdetailsform",
  initialState,
  reducers: {
    setHandleRatingOpen: (state, action) => {
      state.licenseRatingOpen = action.payload;
    },
    setAgreementRatingOpen: (state, action) => {
      state.agreementRatingOpen = action.payload;
    },
    setHandleUserDialog: (state, action) => {
      state.dialogOpen = action.payload;
    },
    setHandleOrderID: (state, action) => {
      state.orderID = action.payload;
    },
    setClearStep: (state) => {
      state.currentStep = 1;
    },
    setCurrentStep: (state) => {
      state.currentStep += 1;
    },
    preCurrentStep: (state, action) => {
      state.currentStep = action.payload - 1;
    },
    setCompanyDetails: (state, action) => {
      state.companyDetails = action.payload;
    },
    setLLPDetails: (state, action) => {
      state.llpDetails = action.payload;
    },
    // setFormDetails: (state, action) => {
    //   state.formDetails = action.payload;
    // }

    setTrademarkRating: (state, action) => {
      state.trademarkRatingOpen = action.payload;
    },
    setLicenseRating: (state, action) => {
      state.licenseRatingOpen = action.payload;
    },
    setAgreementRating: (state, action) => {
      state.agreementRatingOpen = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setHandleRatingOpen,
  setAgreementRatingOpen,
  setHandleUserDialog,
  setHandleOrderID,
  setClearStep,
  setCurrentStep,
  preCurrentStep,
  setCompanyDetails,
  setLLPDetails,

  // Rating
  setTrademarkRating,
  setLicenseRating,
  setAgreementRating,
} = importantdetailsformSlice.actions;

export default importantdetailsformSlice.reducer;
